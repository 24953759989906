export const productsData = [
  {
    imageSrc: "/img/img-9.webp",
    prodTitle: "Kelulut Honey",
    prodDesc:
      "Sourced from trusted beekeepers, our stingless honey bees are renowned for their distinct flavor and numerous health benefits.",
  },
  {
    imageSrc: "/img/img-3.jpg",
    prodTitle: "Coconut Briquette",
    prodDesc:
      "Our coconut briquettes are eco-friendly alternatives to traditional charcoal, providing clean-burning fuel for indoor and outdoor applications. | Size 2.2cm - 2.5cm",
  },
  {
    imageSrc: "/img/img-13.jpg",
    prodTitle: "Oil Palm Fronds",
    prodDesc:
      "We supply high-quality oil palm fronds used for various applications like animal feed, composting, and packaging material. | Length 80cm",
  },
  {
    imageSrc: "/img/img-6.jpg",
    prodTitle: "Barley (Jelai)",
    prodDesc:
      "Enjoy the nutritional benefits of our premium barley, a versatile and healthy grain used in a wide range of culinary creations.",
  },
];

export const galleryData = [
  {
    imageSrc: "/img/img-3.jpg",
  },
  {
    imageSrc: "/img/img-7.webp",
  },
  {
    imageSrc: "/img/img-13.jpg",
  },
  {
    imageSrc: "/img/img-5.jpg",
  },
  {
    imageSrc: "/img/img-8.webp",
  },
  {
    imageSrc: "/img/img-6.jpg",
  },
  {
    imageSrc: "/img/img-9.webp",
  },
  {
    imageSrc: "/img/img-7.jpg",
  },
  {
    imageSrc: "/img/img-10.webp",
  },
  {
    imageSrc: "/img/img-11.webp",
  },
  {
    imageSrc: "/img/img-12.webp",
  },
];
