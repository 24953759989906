import React from "react";
import styles from "./styles/feature.module.css";

const Feature = ({
  version,
  imageSrc,
  sectionId,
  sectionTitle,
  sectionDesc,
}) => {
  if (version === "revert") {
    return (
      <section
        id={sectionId}
        section-view-id={sectionId}
        className={styles.feature}
        style={{ flexWrap: "wrap-reverse" }}
      >
        <div className={styles.featureHead}>
          <h1 className={styles.featureTitle}>{sectionTitle}</h1>
          <p className={styles.featureDesc}>{sectionDesc}</p>
        </div>
        <img
          className={styles.featureImageIcon}
          loading="lazy"
          alt={sectionTitle}
          src={imageSrc}
        />
      </section>
    );
  } else {
    return (
      <section
        id={sectionId}
        section-view-id={sectionId}
        className={styles.feature}
      >
        <img
          className={styles.featureImageIcon}
          loading="lazy"
          alt={sectionTitle}
          src={imageSrc}
        />
        <div className={styles.featureHead}>
          <h1 className={styles.featureTitle}>{sectionTitle}</h1>
          <p className={styles.featureDesc}>{sectionDesc}</p>
        </div>
      </section>
    );
  }
};

export default Feature;
