import React from "react";
import { Button } from "@ibrahimstudio/button";
import { Input } from "@ibrahimstudio/input";
import { link } from "../utilities/helper";
import { PhoneIcon, MailIcon, MapsIcon } from "../components/icons";
import styles from "./styles/contact.module.css";

const Contact = ({ sectionId }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("form submitted");
  };

  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.contact}
    >
      <div className={styles.contactHeadWrap}>
        <div className={styles.contactHead}>
          <h1 className={styles.contactTitle}>Connect with Us Today</h1>
          <p className={styles.contactDesc}>
            Have any queries or want to place an order? Reach out to us using
            the contact form below or get in touch via phone or email. Our
            dedicated team is ready to assist you every step of the way.
          </p>
        </div>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <div className={styles.inputWrap}>
            <Input
              id="client-name"
              radius="md"
              labelText="Your Name"
              type="text"
              placeholder="e.g. John Doe"
              isRequired
            />
            <Input
              id="client-phone"
              radius="md"
              labelText="Phone Number"
              type="tel"
              placeholder="0885 xxxx"
              isRequired
            />
          </div>
          <div className={styles.inputWrap}>
            <Input
              id="client-email"
              radius="md"
              labelText="Email Address"
              type="email"
              placeholder="myaccount@mail.com"
              isRequired
            />
            <Input
              id="client-subject"
              radius="md"
              labelText="Subject"
              type="text"
              placeholder="Email Subject"
              isRequired
            />
          </div>
          <div className={styles.inputWrap}>
            <Input
              id="client-message"
              variant="textarea"
              rows={4}
              radius="md"
              labelText="Message"
              type="text"
              placeholder="Input your message here ..."
              isRequired
            />
          </div>
          <Button
            id="send-contact-form"
            type="submit"
            buttonText="Send Message"
            radius="full"
          />
        </form>
      </div>
      <div className={styles.contactBody}>
        <div
          className={styles.contactCard}
          onClick={() => link("https://wa.me/6289527679885")}
        >
          <div
            style={{
              display: "flex",
              width: "var(--pixel-35)",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
            }}
          >
            <PhoneIcon width="100%" height="100%" />
          </div>
          <div className={styles.contactcardHead}>
            <h1 className={styles.contactcardTitle}>Phone</h1>
            <h2 className={styles.contactcardSubtitle}>+62 8952 7679 885</h2>
          </div>
        </div>
        <div
          className={styles.contactCard}
          onClick={() => link("mailto:ee77.sep@gmail.com")}
        >
          <div
            style={{
              display: "flex",
              width: "var(--pixel-35)",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
            }}
          >
            <MailIcon width="100%" height="100%" />
          </div>
          <div className={styles.contactcardHead}>
            <h1 className={styles.contactcardTitle}>Email</h1>
            <h2 className={styles.contactcardSubtitle}>ee77.sep@gmail.com</h2>
          </div>
        </div>
        <div
          className={styles.contactCard}
          onClick={() => link("https://maps.app.goo.gl/NLp7T6nd1gZ31U819")}
        >
          <div
            style={{
              display: "flex",
              height: "var(--pixel-28)",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
            }}
          >
            <MapsIcon width="100%" height="100%" />
          </div>
          <div className={styles.contactcardHead}>
            <h1 className={styles.contactcardTitle}>Location</h1>
            <h2 className={styles.contactcardSubtitle}>See Location on Maps</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
