import React, { useState } from "react";
import { galleryData } from "../utilities/data";
import { Button } from "@ibrahimstudio/button";
import styles from "./styles/gallery.module.css";

const Gallery = ({ sectionId }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded((prevExpand) => !prevExpand);
  };

  const displayLimit = expanded ? galleryData.length : 8;

  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.gallery}
    >
      <div className={styles.galleryHead}>
        <h1 className={styles.galleryTitle}>Discover Our Export Portfolio</h1>
      </div>
      <div className={styles.galleryBody}>
        {galleryData.slice(0, displayLimit).map((image, index) => (
          <img
            key={index}
            className={styles.galleryImageIcon}
            loading="lazy"
            alt={`Salniedav Ekspor Indonesia gallery ${index}`}
            src={image.imageSrc}
          />
        ))}
      </div>
      {galleryData.length > 8 && (
        <Button
          id={expanded ? "less-gallery" : "expand-gallery"}
          buttonText={expanded ? "Show Less" : "Display All Photos"}
          radius="full"
          onClick={toggleExpand}
        />
      )}
    </section>
  );
};

export default Gallery;
