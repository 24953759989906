import React from "react";
import Nav from "../components/nav";
import Hero from "../sections/hero";
import About from "../sections/about";
import Feature from "../sections/feature";
import Offers from "../sections/offers";
import Products from "../sections/products";
import Gallery from "../sections/gallery";
import Contact from "../sections/contact";
import Footer from "../components/footer";
import styles from "./styles/home.module.css";

const HomePage = () => {
  return (
    <div id="salniedav-indonesia-homepage" className={styles.main}>
      <Nav componentId="top-navigation" />
      <Hero sectionId="main-screen" />
      <About sectionId="about-us" />
      <Feature
        version="revert"
        imageSrc="/img/img-1.jpg"
        sectionTitle="Our Vision"
        sectionDesc="Our vision is to become the preferred global supplier of stingless honey bees, coconut briquette, oil palm fronds, and barley. We aim to build long-lasting partnerships by consistently delivering superior products and exceptional customer service."
      />
      <Feature
        imageSrc="/img/img-5.jpg"
        sectionTitle="Our Mission"
        sectionDesc="Our mission is to meet the global demand for high-quality products by leveraging our expertise in the export sector. We are committed to upholding ethical business practices, promoting sustainability, and exceeding customer expectations at every step."
      />
      <Offers sectionId="our-facility" />
      <Products sectionId="our-products" />
      <Gallery sectionId="our-gallery" />
      <Contact sectionId="contact-us" />
      <Footer componentId="bottom-navigation" />
    </div>
  );
};

export default HomePage;
