import React from "react";
import { Button } from "@ibrahimstudio/button";
import styles from "./styles/prod-card.module.css";

const ProdCard = ({ version, imageSrc, prodTitle, prodDesc }) => {
  if (version === "revert") {
    return (
      <div className={styles.prodCard} style={{ flexWrap: "wrap-reverse" }}>
        <div className={styles.prodBody}>
          <div className={styles.prodHead}>
            <h1 className={styles.prodTitle}>{prodTitle}</h1>
            <h2 className={styles.prodSubtitle}>PRODUCT INFO</h2>
            <p className={styles.prodDesc}>{prodDesc}</p>
          </div>
          <Button
            id={`download-${prodTitle}`}
            buttonText="Download Catalog"
            radius="full"
          />
        </div>
        <img
          className={styles.prodImageIcon}
          loading="lazy"
          alt={prodTitle}
          src={imageSrc}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.prodCard}>
        <img
          className={styles.prodImageIcon}
          loading="lazy"
          alt={prodTitle}
          src={imageSrc}
        />
        <div className={styles.prodBody}>
          <div className={styles.prodHead}>
            <h1 className={styles.prodTitle}>{prodTitle}</h1>
            <h2 className={styles.prodSubtitle}>PRODUCT INFO</h2>
            <p className={styles.prodDesc}>{prodDesc}</p>
          </div>
          <Button
            id={`download-${prodTitle}`}
            buttonText="Download Catalog"
            radius="full"
          />
        </div>
      </div>
    );
  }
};

export default ProdCard;
