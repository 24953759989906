import React from "react";
import { scrollView } from "@ibrahimstudio/function";
import { Button } from "@ibrahimstudio/button";
import styles from "./styles/hero.module.css";

const Hero = ({ sectionId }) => {
  return (
    <header id={sectionId} section-view-id={sectionId} className={styles.hero}>
      <div className={styles.heroHead}>
        <h1 className={styles.heroTitle}>
          Elevate Your Experience with Premium Exports
        </h1>
        <p className={styles.heroDesc}>
          We specialize in supplying top-notch products worldwide, including
          stingless honey bees, coconut briquette, oil palm fronds, and barley.
          Experience the excellence of our premium offerings and join our
          growing list of international customers.
        </p>
        <div className={styles.heroContent}>
          <Button
            id="explore-hero"
            buttonText="Explore More"
            radius="full"
            onClick={() => scrollView(-70, "our-products")}
          />
          <div className={styles.heroFeat}>
            <h2 className={styles.heroFeatText}>15+ Countries</h2>
            <h2 className={styles.heroFeatText}>Export destination</h2>
          </div>
        </div>
      </div>
      <div className={styles.heroBody}>
        <img
          className={styles.heroBodyChild}
          loading="lazy"
          alt={sectionId}
          src="/img/hero.png"
        />
      </div>
    </header>
  );
};

export default Hero;
