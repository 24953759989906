import React from "react";
import { link } from "../utilities/helper";
import { scrollView } from "@ibrahimstudio/function";
import { IgIcon, FbIcon, InIcon } from "./icons";
import styles from "./styles/footer.module.css";

const Footer = ({ componentId }) => {
  return (
    <footer id={componentId} className={styles.footer}>
      <div className={styles.footerBody}>
        <div className={styles.footerIdentity}>
          {/* <img
            onClick={() => scrollView(-70, "main-screen")}
            className={styles.footerLogoIcon}
            alt={componentId}
            src="/img/footer-logo.png"
          /> */}
          <h1
            onClick={() => scrollView(-70, "main-screen")}
            style={{
              display: "flex",
              position: "relative",
              fontSize: "var(--font-medium)",
              fontFamily: "var(--font-sora)",
              color: "var(--color-text)",
            }}
          >
            Salniedav
          </h1>
          <p className={styles.footerAddress}>
            Jl. Gunung Menyapa GG. Guntur Blok B no.15, Timbau Kec. Tenggarong,
            Kalimantan Timur, Indonesia
          </p>
        </div>
        <div className={styles.footerMenu}>
          <h1 className={styles.footerMenuTitle}>Navigation</h1>
          <div className={styles.footerMenuUl}>
            <div
              className={styles.footerMenuLi}
              onClick={() => scrollView(-70, "main-screen")}
            >
              Home
            </div>
            <div
              className={styles.footerMenuLi}
              onClick={() => scrollView(-70, "our-facility")}
            >
              Offers
            </div>
            <div
              className={styles.footerMenuLi}
              onClick={() => scrollView(-70, "our-products")}
            >
              Products
            </div>
            <div
              className={styles.footerMenuLi}
              onClick={() => scrollView(-70, "our-gallery")}
            >
              Gallery
            </div>
          </div>
        </div>
        <div className={styles.footerMenu}>
          <h1 className={styles.footerMenuTitle}>Company</h1>
          <div className={styles.footerMenuUl}>
            <div
              className={styles.footerMenuLi}
              onClick={() => link("https://wa.me/6289527679885")}
            >
              Legal
            </div>
            <div
              className={styles.footerMenuLi}
              onClick={() => link("https://wa.me/6289527679885")}
            >
              Careers
            </div>
            <div
              className={styles.footerMenuLi}
              onClick={() => link("https://wa.me/6289527679885")}
            >
              Partnership
            </div>
            <div
              className={styles.footerMenuLi}
              onClick={() => link("https://maps.app.goo.gl/NLp7T6nd1gZ31U819")}
            >
              Location
            </div>
          </div>
        </div>
        <div className={styles.footerMenu}>
          <h1 className={styles.footerMenuTitle}>Socials</h1>
          <div className={styles.footerMenuIcons}>
            <div className={styles.socialIcon}>
              <div
                style={{
                  display: "flex",
                  width: "var(--pixel-25)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IgIcon width="100%" color="var(--color-text)" />
              </div>
            </div>
            <div className={styles.socialIcon}>
              <div
                style={{
                  display: "flex",
                  width: "var(--pixel-25)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FbIcon width="100%" color="var(--color-text)" />
              </div>
            </div>
            <div className={styles.socialIcon}>
              <div
                style={{
                  display: "flex",
                  width: "var(--pixel-25)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InIcon width="100%" color="var(--color-text)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 className={styles.footerFooter}>
        © 2024 Salniedav. All rights reserved.
      </h1>
    </footer>
  );
};

export default Footer;
