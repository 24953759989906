import React from "react";
import styles from "./styles/about.module.css";

const About = ({ sectionId }) => {
  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.about}
    >
      <div className={styles.aboutHead}>
        <h2 className={styles.aboutSubtitle}>ABOUT US</h2>
        <h1 className={styles.aboutTitle}>
          Your Trusted Partner in Exporting Premium Products, Globally
        </h1>
        <p className={styles.aboutDesc}>
          With years of expertise in the export sector, we have established
          ourselves as a leading name in supplying high-quality products to
          international markets. Our commitment to excellence, sustainable
          practices, and customer satisfaction sets us apart from the
          competition.
        </p>
      </div>
      <div className={styles.aboutBody}>
        <img
          className={styles.videoEmbed}
          alt={sectionId}
          loading="lazy"
          src="/img/about-image.jpg"
        />
      </div>
    </section>
  );
};

export default About;
