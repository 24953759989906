import React from "react";
import { StarIcon, WalletIcon, ThunderIcon } from "../components/icons";
import OffersCard from "../components/offers-card";
import styles from "./styles/offers.module.css";

const Offers = ({ sectionId }) => {
  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.offers}
    >
      <div className={styles.offersHead}>
        <h1 className={styles.offersTitle}>Why Choose Us?</h1>
        <p className={styles.offersDesc}>
          When it comes to sourcing exceptional products, we stand out in the
          market. Here are a few reasons why customers choose us:
        </p>
      </div>
      <div className={styles.offersBody}>
        <OffersCard
          iconContent={<StarIcon width="100%" height="100%" />}
          cardTitle="Superior Quality"
          cardDesc="Our stingless honey bees, coconut briquette, oil palm fronds, and barley are meticulously sourced and undergo rigorous quality checks."
        />
        <OffersCard
          iconContent={<WalletIcon width="100%" height="100%" />}
          cardTitle="Competitive Pricing"
          cardDesc="With our efficient supply chain and strong relationships with suppliers, we offer competitive pricing without compromising on quality."
        />
        <OffersCard
          iconContent={<ThunderIcon width="100%" height="100%" />}
          cardTitle="Excellent Service"
          cardDesc="We strive to exceed customer expectations by providing personalized assistance, prompt responses, and reliable support."
        />
      </div>
    </section>
  );
};

export default Offers;
