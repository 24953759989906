import React from "react";
import styles from "./styles/offers-card.module.css";

const OffersCard = ({ iconContent, cardTitle, cardDesc }) => {
  return (
    <div className={styles.offersCard}>
      <div
        style={{
          display: "flex",
          height: "var(--pixel-35)",
          alignItems: "center",
          justifyContent: "center",
          color: "inherit",
        }}
      >
        {iconContent}
      </div>
      <div className={styles.offerscardHead}>
        <h1 className={styles.offerscardTitle}>{cardTitle}</h1>
        <p className={styles.offerscardDesc}>{cardDesc}</p>
      </div>
    </div>
  );
};

export default OffersCard;
